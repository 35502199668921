// Default Colors
$white: rgb(255, 255, 255); // #FFFFFF
$black:rgb(13, 24, 33); // #0D1821
$text: $black;
$text-gray: rgb(82, 82, 82); // #71716F
$text-gray2: lighten(rgb(113, 113, 111), 30%); // #71716F
$text-gray3: lighten(rgb(113, 113, 111), 20%); // #71716F
$light-blue-bg: lighten(rgb(244, 246, 246), 3%);// #F4F6F6
$blue-bg: rgb(244, 246, 246); // #F4F6F6
$dark-blue-bg: rgb(233, 238, 238); // #E9EEEE
$blue-dark: rgb(36, 131, 159); // #24839f
$yellow_light:lighten(rgb(249, 236, 144), 10%); // #F9EC90
$yellow: rgb(249, 236, 144); // #F9EC90
$yellow_dark: rgb(227, 212, 31); // #E3D41F;
$red: rgb(207, 50, 36); // #CF3224
$orange: #ffa500;
$green: rgb(77, 164, 118); // #4DA476
$buttonRed: #f5322e;
$buttonRedDarken: darken($buttonRed, 40%);
$iconDark: #222;

// Gray Colors
$background: #f4f0ec; // #F3F3F3
$light-gray-100: rgb(242, 242, 242); // #F2F2F2
$light-gray: rgb(243, 243, 244); // #F3F3F4
$light-gray-500: rgb(237, 237, 237); // #EDEDED
$border: rgb(208, 208, 207); // #d0d0cf
$border2: rgba(151, 151, 151, 0.1); // #979797
$border3: rgb(205, 205, 205); // #CDCDCD
$grey: rgb(128, 128, 128); // #808080
$grey-border: rgb(216, 216, 216); // #D8D8D8
$grey-bg: rgb(243, 243, 242); //#F3F3F2
$gray2: rgb(207, 209, 211); // #CFD1D3
$gray3: lighten(rgb(207, 209, 211), 10%); // #CFD1D3
$greyBlue: #e7e4eb;
