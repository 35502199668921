@mixin tall-screen {
    @media (min-height: 1200px) and (min-width: $tablet) {
        @content;
    }
}

@mixin desktop-max-xl {
    @media (min-width: 1400px) {
        @content;
    }
}
@mixin desktop-max {
    @media (min-width: 1000px) {
        @content;
    }
}

@mixin desktop-big {
    @media (min-width: $desktop-big) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-sm) {
        @content;
    }
}

@mixin before-desktop { // DO NOT USE THIS!
    @media (max-width: $tablet-landscape) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (min-width: $tablet-landscape) {
        @content;
    }
}

@mixin tablet-portrait { // DO NOT USE THIS!
    @media (min-width: $tablet-landscape) {
        @content;
    }
}

@mixin tablet { // DO NOT USE THIS!
    @media (min-width: $tablet) {
        @content;
    }
}

@mixin after-mobile {
    @media (min-width: $tablet) {
        @content;
    }
}

@mixin before-desktop {
    @media (max-width: $tablet) {
        @content;
    }
}

@mixin after-mobile-sm {
    @media (min-width: $mobile) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile) {
        @content;
    }
}

@mixin after-mobile-any {
    @media (min-width: $mobile-any) {
        @content;
    }
}

@mixin mobile-sm {
    @media (max-width: $mobile-sm) {
        @content;
    }
}

@mixin small-device {
    @media (min-width: 450px) {
        @content;
    }
}

@mixin standalone {
    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}
