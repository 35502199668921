// animation
$normal: 500ms;
$fast: 300ms;
$shoot: 200ms;
$fast: 150ms;
$rapid: 100ms;

// mixins
$baseFontSize: 16; // 1rem = $baseFontSize

// breakpoints
$desktop-big: calc(1601rem / 16);
$desktop-sm: calc(1200rem / 16);
$tablet-landscape: calc(900rem / 16);
$mobile-any: calc(810rem / 16);
$tablet: calc(811rem / 16);
$mobile: calc(768rem / 16);
$mobile-sm: calc(480rem / 16);

// Default Image Sizes:
:root {
    --categories-menu-image-ratio: 36.666666666666664%; // 30/11 ratio
    --productcard-ratio: 50%; // 2/1 ratio
}

// z-index
$z-indexes: (
    'default': 1,
    'header': 900,
    'menu': 901,
    'offline': 902,
    'overlay': 902,
    'popup': 903,
    'loader': 998,
    'notification': 999,
    'cartsummary': 902,
    'addtocart': 902,
    'stickybuttonwrapper': 901,
    'product-cms-box': 2,
    'sliderwidget-figcaption': 2,
    'threeds2popup': 200,
    'map-attribution': 400,
    'behind': -1,
    'slider-buttons': 100,
    'select': 3,
    'sticky-filter': 899,
    'dropdown': 902,
    'chat': 901
);

.DefaultFont {
    font-family: 'Open Sans', sans-serif;
}

.TitleFont {
    font-family: 'Open Sans', sans-serif;
}

.DisableListType {
    margin-left: 0;
    list-style-type: none;

    // IE/EGDE Fix
    list-style-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP');
}
