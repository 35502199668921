:root {
    --button-default-bg-color: #{$black};
    --button-default-color: #{$white};
    --button-hover-bg: #{$background};
    --button-hover-color: #{$black};
    --button-draw-color: #{$black};
    --button-cta-border-color: #{$border};
    --button-cta-active-bg: #{$border};
    --button-beige-bg-color: #{$background};
}

// conver px to rem
@function rem($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return Calc($pixels / $context * 1rem);
}

// font styling and convert it to rem
@mixin font($size, $lineHeight, $weight: false, $color: false, $ls: false) {
    @if $size {
        font-size: rem($size);
    }
    @if $lineHeight {
        line-height: rem($lineHeight);
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $color {
        color: $color;
    }
    @if $ls {
        letter-spacing: $ls + px;
    }
}

@mixin container($container: --container-max, $gutter: --gutter) {
    max-width: var($container);
    padding-left: var($gutter);
    padding-right: var($gutter);
    margin-right: auto;
    margin-left: auto;
}

@mixin email-icon() {
    //nothing
}

// z-index function
@function z($layer: default) {
    @return map-get($z-indexes, $layer);
}

@mixin button-beige() {
    background-color: var(--button-beige-bg-color);
    color: var(--button-default-bg-color);

    svg g {
        stroke: var(--button-default-bg-color);
    }

    &:hover,
    &:focus,
    &:active {
        background-color: var(--button-default-bg-color);
        color: var(--button-default-color);

        svg g {
            stroke: var(--button-default-color);
        }
    }

    &[disabled] {
        opacity: 1;
        background-color: var(--button-beige-bg-color);
        border-color: var(--button-beige-bg-color);
        color: #888;
        cursor: not-allowed;
    }
}
